var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('PageHeader',{attrs:{"title":_vm.$t('sms.history.header')}}),_c('div',{staticClass:"page-content container-fluid"},[_c('card',{attrs:{"actions":"","block":"","exportAction":"fetchOmniRooms","title":_vm.$tc('app.protocol', 2),"loading":_vm.loading,"filters":_vm.filters},on:{"reload":_vm.fetchRooms,"filter":_vm.applyFilter},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('data-table',{attrs:{"data":_vm.rooms,"pages":_vm.pages,"columns":[
            {
              key: 'creation_date',
              filters: ['date|DD/MM/YYYY'],
              label: 'created_at',
            },
            'protocol',
            {
              key: 'channel',
              class: 'badge badge-primary text-white',
            },
            {
              key: 'open_date',
              filters: ['date|HH:mm:ss'],
              label: 'start_hour',
            },
            {
              key: 'close_date',
              filters: ['date|HH:mm:ss'],
              label: 'end_hour',
            },
            'name',
            'tabulation',
            'evaluation' ]},on:{"change-page":_vm.fetchRooms},scopedSlots:_vm._u([{key:"actions",fn:function(item){return [_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showMessages(item)}}},[_c('i',{staticClass:"fas fa-comments"})])]}}])})]},proxy:true}]),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1),_c('modal',{ref:"messages-modal",attrs:{"title":"Conversa","no-footer":""}},[_c('chat-conversation',{attrs:{"room":_vm.currentRoom}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }