<template>
  <div class="content">
    <PageHeader :title="$t('sms.history.header')" />
    <div class="page-content container-fluid">
      <card
        actions
        block
        exportAction='fetchOmniRooms'
        :title="$tc('app.protocol', 2)"
        :loading="loading"
        @reload="fetchRooms"
        @filter="applyFilter"
        :filters="filters"
        v-model="form"
      >
        <template #body>
          <data-table
            :data="rooms"
            :pages="pages"
            :columns="[
              {
                key: 'creation_date',
                filters: ['date|DD/MM/YYYY'],
                label: 'created_at',
              },
              'protocol',
              {
                key: 'channel',
                class: 'badge badge-primary text-white',
              },
              {
                key: 'open_date',
                filters: ['date|HH:mm:ss'],
                label: 'start_hour',
              },
              {
                key: 'close_date',
                filters: ['date|HH:mm:ss'],
                label: 'end_hour',
              },
              'name',
              'tabulation',
              'evaluation',
            ]"
            @change-page="fetchRooms"
          >
            <template #actions="item">
              <a href="javascript:void(0)" @click="showMessages(item)"
                ><i class="fas fa-comments"></i
              ></a>
            </template>
          </data-table>
        </template>
      </card>
    </div>
    <modal ref="messages-modal" title="Conversa" no-footer>
      <chat-conversation :room="currentRoom" />
    </modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import SmsService from '@/services/sms.service';
import PageHeader from '@/components/PageHeader.vue';
import DataTable from '@/components/DataTable.vue';
import Card from '@/components/Card.vue';
import Modal from '@/components/Modal.vue';
import ChatConversation from '@/components/chat/ChatConversation.vue';
// import moment from 'moment';

export default {
  name: 'SmsHistory',
  components: {
    PageHeader,
    DataTable,
    Card,
    Modal,
    ChatConversation,
  },
  data() {
    return {
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      loading: true,
      filters: [
        {
          name: 'date',
          type: 'range',
          value: [moment().format('DD/MM/YYYY'), moment().format('DD/MM/YYYY')],
        },
        {
          name: 'protocol',
          type: 'text',
          value: '',
        },
        {
          name: 'status',
          type: 'checkbox',
          value: [],
          options: [
            {
              name: 'QUEUE',
              label: 'queued',
            },
            {
              name: 'OPEN',
              label: 'opened',
            },
            {
              name: 'CLOSE',
              label: 'closed',
            },
          ],
        },
        {
          name: 'channel',
          type: 'checkbox',
          value: [],
          options: [
            {
              name: 'whatsapp',
              label: 'whatsapp',
            },
            {
              name: 'instagram',
              label: 'instagram',
            },
            {
              name: 'telegram',
              label: 'telegram',
            },
            {
              name: 'webchat',
              label: 'webchat',
            },
          ],
        },
        {
          name: 'agent',
          type: 'select',
          value: '',
          options: [],
        },
        {
          name: 'tabulation',
          type: 'select',
          value: '',
          options: [],
        },
        {
          name: 'tag',
          type: 'select',
          value: '',
          options: [],
        },
      ],
      rooms: [],
      currentRoom: {},
      agents: [],
      messages: [],
      beginDate: '',
      endDate: '',
      params: [],
      page: 1,
      pages: 1,
      exporting: false,
      form: {},
    };
  },
  created() {
    this.fetchAgents();
    this.fetchTabs();
    this.fetchTags();
  },
  methods: {
    fetchRooms(page) {
      this.page = page;
      this.loading = true;
      this.$store
        .dispatch('fetchOmniRooms', {
          page,
          ...this.form,
        })
        .then(
          (response) => {
            console.log(response);
            this.loading = false;
            this.rooms = response.data.data;
            this.pages = response.data.last_page;
          },
          (error) => {
            this.content = error;
            console.log(JSON.stringify(error.response.data));
          },
        );
    },
    fetchAgents() {
      this.$store.dispatch('fetchOmniAgents').then(
        (response) => {
          this.filters.forEach((filter) => {
            if (filter.name === 'agent') {
              response.forEach((agent) => {
                filter.options.push({
                  name: agent.id,
                  label: agent.name,
                });
              });
            }
          });
          this.agents = response.data;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    fetchTabs() {
      this.$store.dispatch('fetchOmniTabs').then(
        (response) => {
          this.filters.forEach((filter) => {
            if (filter.name === 'tabulation') {
              response.forEach((tab) => {
                filter.options.push({
                  name: tab.id,
                  label: tab.name,
                });
              });
            }
          });
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    fetchTags() {
      this.$store.dispatch('fetchOmniTags').then(
        (response) => {
          this.filters.forEach((filter) => {
            if (filter.name === 'tag') {
              response.forEach((tag) => {
                filter.options.push({
                  name: tag.id,
                  label: tag.name,
                });
              });
            }
          });
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    applyFilter(filters) {
      this.form = filters;
      this.fetchRooms(1);
    },
    fetchRoom(roomId) {
      this.currentRoom = null;
      this.$refs['messages-modal'].toggle();
      this.$store.dispatch('fetchOmniRoom', roomId).then(
        (room) => {
          this.currentRoom = room;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    showMessages(room) {
      this.fetchRoom(room.id);
    },
    changeDate(date) {
      if (date) {
        this.beginDate = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.endDate = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 23:59:59',
        );
      } else {
        this.beginDate = moment().format('YYYY-MM-DD 00:00:00');
        this.endDate = moment().format('YYYY-MM-DD 23:59:59');
      }
      this.form.created_at.gte = this.beginDate;
      this.form.created_at.lte = this.endDate;
    },
    exportMessages() {
      this.exporting = true;
      SmsService.exportSms({
        filter: {
          id: this.form.id,
          from: this.form.from,
          to: this.form.to,
          status: this.form.status,
          sent_at: {
            from: this.beginDate,
            to: this.endDate,
          },
        },
      })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.toast.report'),
              type: 'success',
            });
            this.$router.push('/sms/exports');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
</style>
